import React from "react";
import Head from "../t_Mobile/Head";
import Splash from "../t_Mobile/Splash";
import Top from "../t_Mobile/Top";
import Foot from "../t_Mobile/Foot";
import MenuBawah from "../t_Mobile/MenuBawah";
import MenuBawahGuru from "../t_Mobile/MenuBawahGuru";

const Layouts = ({ children, user }) => {
  return (
    <div>
      {user === "siswa" ? (
        <React.Fragment>
          <Head />
          <div className="scrollbar-hidden">
            {/* <Splash /> */}
            <main className="home">
              <Top />
              {children}
            </main>
            <MenuBawah />
            <Foot />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Head />
          <div className="scrollbar-hidden">
            {/* <Splash /> */}
            <main className="home">
              <Top />
              {children}
            </main>
            <MenuBawahGuru />
            <Foot />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Layouts;
