import React, { useState, useEffect } from "react";
import axios from "axios";

const DetailAbsensiGuru = ({ url }) => {
  const [absensiData, setAbsensiData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAbsensiData();
  }, [currentMonth]);

  const fetchAbsensiData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const dataGuru = JSON.parse(localStorage.getItem("dataGuru"));

      const response = await axios.post(
        `${url}absensi/detail`,
        {
          personnel_id: dataGuru.personnel_id,
          month: currentMonth.getMonth() + 1,
          year: currentMonth.getFullYear(),
          nsm: dataGuru.id_lembaga,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAbsensiData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching absensi data:", error);
    } finally {
      setLoading(false);
    }
  };

  const previousMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );
  };

  const nextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("id-ID", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(date));
  };

  return (
    <div style={{ padding: "20px", marginBottom: "80px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
          background: "white",
          padding: "15px",
          borderRadius: "10px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <button
          onClick={previousMonth}
          style={{
            background: "#e70303",
            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <h2 style={{ margin: 0, fontSize: "1.2rem", color: "#333" }}>
          {new Intl.DateTimeFormat("id-ID", {
            month: "long",
            year: "numeric",
          }).format(currentMonth)}
        </h2>
        <button
          onClick={nextMonth}
          style={{
            background: "#e70303",
            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", padding: "20px", color: "#666" }}>
          Loading...
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {absensiData.map((item) => (
            <div
              key={item.date}
              style={{
                background: "white",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                marginBottom: "10px", // Add this line for spacing
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    fontSize: "1rem",
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  {formatDate(item.date)}
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {item.check_in && (
                  <div style={{ fontSize: "0.9rem", color: "#666" }}>
                    <span>Masuk: {item.check_in}</span>
                  </div>
                )}
                {item.check_out && (
                  <div style={{ fontSize: "0.9rem", color: "#666" }}>
                    <span>Pulang: {item.check_out}</span>
                  </div>
                )}
                {item.reason_id && (
                  <div style={{ fontSize: "0.9rem", color: "#666" }}>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        backgroundColor: "#ffc107",
                        color: "white",
                      }}
                    >
                      Ijin: {item.description}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DetailAbsensiGuru;
