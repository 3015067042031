import React, { useState, useEffect } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import Swal from "sweetalert2";
import "./Dashboard.css";

const Dashboard = ({ url }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [attendanceStatus, setAttendanceStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  //   const [userData, setUserData] = useState(null);
  const [absensiHariIni, setAbsensiHariIni] = useState([]);
  const [absensiBulanIni, setAbsensiBulanIni] = useState([]);

  const [showIjinModal, setShowIjinModal] = useState(false);
  const [keterangan, setKeterangan] = useState("");
  const [reason, setReason] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");

  const [userData, setUserData] = useState({
    name: "Saepul Anwar",
    role: "Guru",
    nip: "198509102019031002",
    mapel: "Matematika",
    stats: {
      hadir: 20,
      izin: 2,
      sakit: 1,
      telat: 3,
    },
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${url}user/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [url]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Add function to get current status on component mount
  useEffect(() => {
    const checkCurrentStatus = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${url}attendance/status`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.status) {
          setAttendanceStatus(response.data.status);
        }
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    checkCurrentStatus();
  }, [url]);

  const handleAttendance = async (type) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const nsm = localStorage.getItem("nsm");

    if (type === "check_in") {
      try {
        // Send to your backend first

        const date = new Date();
        const formattedDate = date.toISOString().split("T")[0];

        const response = await axios.post(`${url}checkin`, {
          date: formattedDate,
          nsm: JSON.parse(localStorage.getItem("dataGuru")).id_lembaga,
          personnel_id: JSON.parse(localStorage.getItem("dataGuru"))
            .personnel_id,
          checkin: `${currentTime
            .getHours()
            .toString()
            .padStart(2, "0")}:${currentTime
            .getMinutes()
            .toString()
            .padStart(2, "0")}`,
        });
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Anda berhasil melakukan absensi masuk",
          });
        }
        getAbsensiHariIni();
        getAbsensiBulanIni();
      } catch (error) {
        console.error("Error submitting attendance:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response?.data?.message || "Gagal melakukan absensi",
        });
      } finally {
        setLoading(false);
      }
    } else if (type === "check_out") {
      try {
        // Send to your backend first

        const date = new Date();
        const formattedDate = date.toISOString().split("T")[0];

        const response = await axios.post(`${url}checkout`, {
          date: formattedDate,
          nsm: JSON.parse(localStorage.getItem("dataGuru")).id_lembaga,
          personnel_id: JSON.parse(localStorage.getItem("dataGuru"))
            .personnel_id,
          checkout: `${currentTime
            .getHours()
            .toString()
            .padStart(2, "0")}:${currentTime
            .getMinutes()
            .toString()
            .padStart(2, "0")}`,
        });
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Anda berhasil melakukan absensi pulang",
          });
        }
        getAbsensiHariIni();
        getAbsensiBulanIni();
      } catch (error) {
        console.error("Error submitting attendance:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response?.data?.message || "Gagal melakukan absensi",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getAbsensiHariIni = async () => {
    const date = new Date();
    try {
      const response = await axios.post(`${url}cekAbsenToday`, {
        nsm: JSON.parse(localStorage.getItem("dataGuru")).id_lembaga,
        personnel_id: JSON.parse(localStorage.getItem("dataGuru")).personnel_id,
        date: date.toISOString().split("T")[0],
      });
      setAbsensiHariIni(response.data.data);
    } catch (error) {
      console.error("Error fetching absensi:", error);
    }
  };

  const getAbsensiBulanIni = async () => {
    const date = new Date();
    try {
      const response = await axios.post(`${url}rekap-bulan-ini`, {
        nsm: JSON.parse(localStorage.getItem("dataGuru")).id_lembaga,
        personnel_id: JSON.parse(localStorage.getItem("dataGuru")).personnel_id,
        month: date.getMonth() + 1,
        years: date.getFullYear(),
      });
      setAbsensiBulanIni(response.data.data);
    } catch (error) {
      console.error("Error fetching absensi:", error);
    }
  };
  const getReason = async () => {
    try {
      const response = await axios.post(`${url}reason`);
      setReason(response.data.data);
    } catch (error) {
      console.error("Error fetching reason:", error);
    }
  };

  const handleIjinSubmit = async () => {
    if (!selectedReason) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Silakan pilih alasan ijin!",
      });
      return;
    }

    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0];

    try {
      setLoading(true);

      // Find the selected reason object to get its name
      const selectedReasonObj = reason.find(
        (r) => r.id === parseInt(selectedReason)
      );

      await axios.post(`${url}ijin`, {
        reason_id: selectedReason,
        deskripsi: selectedReasonObj?.name,
        date: formattedDate,
        nsm: JSON.parse(localStorage.getItem("dataGuru")).id_lembaga,
        personnel_id: JSON.parse(localStorage.getItem("dataGuru")).personnel_id,
      });

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Ijin berhasil diajukan",
      });
      setShowIjinModal(false);
      setSelectedReason("");
    } catch (error) {
      console.error("Error submitting ijin:", error.response?.data?.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message,
      });
    } finally {
      setLoading(false);
      getAbsensiHariIni();
      getAbsensiBulanIni();
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("id-ID", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  }

  useEffect(() => {
    getAbsensiHariIni();
    getAbsensiBulanIni();
    getReason();
  }, []);

  return (
    <>
      <section className="info d-flex align-items-start justify-content-between pb-12">
        <div className="d-flex align-items-center justify-content-between gap-14">
          <img src="/assets/img/logo.png" alt="logo" width={120} />
        </div>

        <ul className="d-flex align-items-center gap-16">
          <li>
            <a
              href="#"
              className="d-flex align-items-center justify-content-center rounded-full position-relative"
            >
              <img src="/assets_mobile/svg/bell-black.svg" alt="icon" />
              <span className="dot"></span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="d-flex align-items-center justify-content-center rounded-full position-relative"
            >
              <img
                src="/assets_mobile/svg/message-square-dots.svg"
                alt="icon"
              />
              <span class="dot"></span>
            </a>
          </li>
        </ul>
      </section>

      <section>
        <div className="ticket-card radius-8">
          <div className="card-title d-flex align-items-center justify-content-between">
            <h4>Selamat Datang</h4>
            <span>Identitas</span>
          </div>

          <div className="card-item d-flex align-items-center gap-16 w-100">
            <div className="image shrink-0 overflow-hidden radius-8">
              <img
                src="/assets_mobile/images/home/avatar.png"
                alt="Place"
                className="img-fluid w-100 h-100 object-fit-cover"
              />
            </div>

            <div className="content flex-grow">
              <h4>{JSON.parse(localStorage.getItem("dataGuru")).full_name}</h4>
              <p className="d-flex align-items-center gap-04 location mt-04">
                NIK : {JSON.parse(localStorage.getItem("dataGuru")).nik}
              </p>
              <p className="d-flex align-items-center gap-04 location mt-04">
                Status :{" "}
                {JSON.parse(localStorage.getItem("dataGuru")).status_name} /{" "}
                {JSON.parse(localStorage.getItem("dataGuru")).personnel_type}
              </p>
              <p className="d-flex align-items-center gap-04 location mt-04">
                Tanggal Lahir :
                {formatDate(
                  JSON.parse(localStorage.getItem("dataGuru")).birth_date
                )}
              </p>
            </div>
          </div>

          <div className="card-footer d-flex align-items-center justify-content-between">
            <div>
              <p>Institution : </p>
              <h3>
                {
                  JSON.parse(localStorage.getItem("dataGuru"))
                    .institution_base_name
                }
              </h3>
              <p>
                {JSON.parse(localStorage.getItem("dataGuru")).id_lembaga} -{" "}
                {JSON.parse(localStorage.getItem("dataGuru")).npsn}
              </p>
            </div>

            {/* <a href="#">Detail</a> */}
          </div>
        </div>
      </section>

      <section>
        <div className="dashboard-container">
          <div className="profile-card">
            <div className="profile-info">
              <p className="user-role">{userData.role}</p>
            </div>
            <div className="time-display">
              <div className="current-time">
                {currentTime.toLocaleTimeString()}
              </div>
              <div className="current-date">
                {currentTime.toLocaleDateString("id-ID", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </div>
            </div>
          </div>

          <div className="attendance-buttons">
            <button
              onClick={() => handleAttendance("check_in")}
              disabled={loading || attendanceStatus === "check_in"}
              className="attendance-btn datang"
            >
              <div className="btn-icon">
                <i className="fas fa-sign-in-alt"></i>
              </div>
              <div className="btn-text">DATANG</div>
              <div className="btn-status">
                {absensiHariIni.check_in !== null
                  ? "Sudah Absen"
                  : "Belum Absen"}
              </div>
            </button>

            <button
              onClick={() => handleAttendance("check_out")}
              disabled={loading || attendanceStatus === "check_out"}
              className="attendance-btn pulang"
            >
              <div className="btn-icon">
                <i className="fas fa-sign-out-alt"></i>
              </div>
              <div className="btn-text">PULANG</div>
              <div className="btn-status">
                {absensiHariIni.check_out !== null
                  ? "Sudah Pulang"
                  : "Belum Pulang"}
              </div>
            </button>
          </div>

          <button
            onClick={() => setShowIjinModal(true)}
            disabled={loading || attendanceStatus === "ijin"}
            className="small-btn ijin"
          >
            <i className="fas fa-envelope"></i>
            <span>IJIN</span>
          </button>

          <div className="attendance-card">
            <div className="card-content">
              <h3>Absensi Hari Ini</h3>
              <div className="time-info">
                {absensiHariIni.reason_id !== null ? (
                  <div className="time-item-ijin">
                    {/* <span className="time-label-ijin">Izin</span> */}
                    <span className="time-value-ijin">
                      IJIN -{absensiHariIni.description}
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="time-item">
                      <span className="time-label">Masuk:</span>
                      <span className="time-value">
                        {absensiHariIni.check_in || "Belum Absen"}
                      </span>
                    </div>
                    <div className="time-item">
                      <span className="time-label">Pulang:</span>
                      <span className="time-value">
                        {absensiHariIni.check_out || "Belum Absen"}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="stats-card">
            <div className="stats-header">Rekap Absensi Bulan Ini</div>
            <div className="stats-grid">
              <div className="stat-item">
                <div className="stat-value">{absensiBulanIni.hariefektif}</div>
                <div className="stat-label">Hari Efektif</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">{absensiBulanIni.hadir}</div>
                <div className="stat-label">Hadir</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">{absensiBulanIni.ijin}</div>
                <div className="stat-label">Ijin</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">{absensiBulanIni.belumabsen}</div>
                <div className="stat-label">Belum Absen</div>
              </div>
            </div>
          </div>

          {showIjinModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2>Form Ijin</h2>
                <select
                  className="ijin-select form-control"
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                >
                  <option value="">Pilih Alasan Ijin</option>
                  {reason.map((reason) => (
                    <option key={reason.id} value={reason.id}>
                      {reason.name}
                    </option>
                  ))}
                </select>
                <div className="modal-buttons mt-3">
                  <button
                    onClick={() => {
                      setShowIjinModal(false);
                      setSelectedReason("");
                    }}
                    className="cancel-btn"
                  >
                    Batal
                  </button>
                  <button
                    onClick={handleIjinSubmit}
                    className="submit-btn mt-3"
                    disabled={loading}
                  >
                    {loading ? "Memproses..." : "Kirim"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Dashboard;
