import React from "react";
import Layouts from "./Layouts";
import DetailAbsensiGuru from "../../Mobile/Guru/DetailAbsensiGuru";

const DetailAbsensi = ({ url }) => {
  return (
    <div>
      <Layouts user="guru">
        <DetailAbsensiGuru url={url} />
      </Layouts>
    </div>
  );
};

export default DetailAbsensi;
