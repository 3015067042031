import React from "react";

const Splash = () => {
  return (
    <div>
      <section id="preloader" className="spalsh-screen">
        <div className="circle text-center">
          <div>
            <h1>Travgo</h1>
            <p>Discover Your Destination</p>
          </div>
        </div>
        <div className="loader-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
    </div>
  );
};

export default Splash;
