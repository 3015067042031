import React from "react";
import Layouts from "./Layouts";
import Dashboard from "../../Mobile/Guru/Dashboard";

const HomeGuru = ({ url }) => {
  return (
    <div>
      <Layouts user="guru">
        <Dashboard url={url} />
      </Layouts>
    </div>
  );
};

export default HomeGuru;
